<template>
  <div>
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ "Add New Record".toUpperCase() }}
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form
          class="mt-1"
          @submit.prevent="handleSubmit(onSubmit)"
          :key="trigger"
        >
          <div v-if="dataArr.length > 0" class="mt-2 mt-sm-3 position-relative">
            <feather-icon
              size="28"
              style="cursor: pointer; color: #ff0000"
              class="mr-1 close-excel-tb"
              @click="dataArr = []"
              icon="XCircleIcon"
            />
            <hr />

            <div
              ref="form"
              class="invoice-items repeater-form"
              :style="{ height: trHeight }"
            >
              <b-row
                v-for="(item, index) in dataArr"
                :key="index"
                ref="row"
                class="pb-2"
              >
                <b-col cols="12">
                  <div
                    class="d-flex border rounded"
                    :class="
                      item.message && Object.keys(item.message).length > 0
                        ? 'redBorder'
                        : ''
                    "
                  >
                    <b-row class="flex-grow-1 p-2">
                      <!-- Field: import Year -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_year${index}`"
                          :name="index == 0 ? 'Year' : 'Year (' + index + ')'"
                          rules="required"
                        >
                          <!-- :class="" -->
                          <b-form-group
                            class="required"
                            :label="
                              index == 0 ? 'Year' : 'Year (' + index + '):'
                            "
                            :label-for="`import_year_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="!item.year ? 'redBorder' : ''"
                            >
                              <v-select
                                :id="`import_year_${index}`"
                                v-model="item.year"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="years"
                                :clearable="false"
                                placeholder="Select year"
                                @input="
                                  () => {
                                    delete item.message.year;
                                  }
                                "
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Field: import Quarter -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_quarter${index}`"
                          :name="
                            index == 0 ? 'Quarter' : 'Quarter (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Quarter'
                                : 'Quarter (' + index + '):'
                            "
                            :label-for="`import_quarter_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="!item.quarter ? 'redBorder' : ''"
                            >
                              <v-select
                                :id="`import_quarter_${index}`"
                                v-model="item.quarter"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="formOptions.quarter"
                                :clearable="false"
                                label="name"
                                :reduce="(option) => option.id"
                                placeholder="Select quarter"
                                @input="
                                  () => {
                                    delete item.message.quarter;
                                  }
                                "
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Field: import JVSakk A/C No. / client_id -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_jv_c${index}`"
                          :name="
                            index == 0
                              ? 'JVSakk A/C No.'
                              : 'JVSakk A/C No. (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'JVSakk A/C No.'
                                : 'JVSakk A/C No. (' + index + '):'
                            "
                            :label-for="`import_client_id_${index}`"
                            label-cols-md="4"
                          >
                            <div :class="!item.client_id ? 'redBorder' : ''">
                              <span v-if="item.client_id" class="mr-2">{{
                                item.client_id
                              }}</span>
                              <b-button
                                class="bgColor3 px-3"
                                @click="openJVModal(item)"
                                >Select</b-button
                              >
                            </div>
                            <b-form-input
                              :id="`import_client_id_${index}`"
                              trim
                              placeholder=""
                              style="display: none"
                              v-model="item.client_id"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Field: import Custodian Bank (bank_id)-->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_bank_id_${index}`"
                          :name="
                            index == 0
                              ? 'Custodian Bank'
                              : 'Custodian Bank (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Custodian Bank'
                                : 'Custodian Bank (' + index + '):'
                            "
                            :label-for="`import_bank_id_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="!item.bank_id ? 'redBorder' : ''"
                            >
                              <v-select
                                :id="`import_bank_id_${index}`"
                                v-model="item.bank_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="
                                  bankOptions.filter((bank) =>
                                    availableBank.includes(bank.id)
                                  )
                                "
                                label="name"
                                :clearable="false"
                                :reduce="(option) => option.id"
                                placeholder="Select custodian bank"
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Account No (client_bank_id)-->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_client_bank_id_${index}`"
                          :name="
                            index == 0
                              ? 'Account No.'
                              : 'Account No. (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Account No.'
                                : 'Account No. (' + index + '):'
                            "
                            :label-for="`import_client_bank_id_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="!item.client_bank_id ? 'redBorder' : ''"
                            >
                              <v-select
                                :id="`import_client_bank_id_${index}`"
                                v-model="item.client_bank_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="
                                  clientBankOptions.filter(
                                    (account) =>
                                      account.bank_id == item.bank_id &&
                                      account.client_id == item.client_id
                                  )
                                "
                                :clearable="false"
                                label="account_number"
                                :reduce="(option) => option.id"
                                placeholder="Select account no."
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>

                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Client Name -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_client_name_${index}`"
                          :name="
                            index == 0
                              ? 'Client Name'
                              : 'Client Name (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Client Name'
                                : 'Client Name (' + index + '):'
                            "
                            :label-for="`import_client_name_${index}`"
                            label-cols-md="4"
                          >
                            <div :class="!item.client_name ? 'redBorder' : ''">
                              <b-form-input
                                :id="`import_client_name_${index}`"
                                :state="getValidationState(validationContext)"
                                v-model="item.client_name"
                                placeholder="Enter Client Name"
                              />
                            </div>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Chinese Name -->
                      <b-col cols="12" md="6">
                          <b-form-group
                            class=""
                            :label="
                              index == 0
                                ? 'Chinese Name'
                                : 'Chinese Name (' + index + '):'
                            "
                            :label-for="`import_chinese_name_${index}`"
                            label-cols-md="4"
                          >
                            <div>
                              <b-form-input
                                :id="`import_chinese_name_${index}`"
                                v-model="item.chinese_name"
                                placeholder="Enter client’s Chinese name"
                              />
                            </div>
                          </b-form-group>
                      </b-col>

                      <!-- Field: import Relationship Manager -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_relationship_manager_id_${index}`"
                          :name="
                            index == 0
                              ? 'Relationship Manager'
                              : 'Relationship Manager (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Relationship Manager'
                                : 'Relationship Manager (' + index + '):'
                            "
                            :label-for="`import_relationship_manager_id_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="
                                !item.relationship_manager_id ? 'redBorder' : ''
                              "
                            >
                              <v-select
                                :id="`import_relationship_manager_id_${index}`"
                                v-model="item.relationship_manager_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="userOptions"
                                :clearable="false"
                                label="name"
                                :reduce="(option) => option.id"
                                placeholder="Select Relationship Manager"
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Currency -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_currency_${index}`"
                          :name="
                            index == 0 ? 'Currency' : 'Currency (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Currency'
                                : 'Currency(' + index + '):'
                            "
                            :label-for="`import_currency_${index}`"
                            label-cols-md="4"
                          >
                            <div
                              class="form-col-select"
                              :class="!item.currency_id ? 'redBorder' : ''"
                            >
                              <v-select
                                :id="`import_currency_${index}`"
                                v-model="item.currency_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="currencyOptions"
                                :clearable="false"
                                label="currency"
                                :reduce="(option) => option.id"
                                placeholder="Select currency"
                                @input="() => delete item.message.currency_id"
                              />
                              <feather-icon size="18" icon="ChevronDownIcon" />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Amount -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_amount_${index}`"
                          :name="
                            index == 0 ? 'Amount' : 'Amount (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0 ? 'Amount' : 'Amount(' + index + '):'
                            "
                            :label-for="`import_amount_${index}`"
                            label-cols-md="4"
                          >
                            <div :class="!item.amount ? 'redBorder' : ''">
                              <b-form-input
                                :id="`import_amount_${index}`"
                                :state="getValidationState(validationContext)"
                                v-model="item.amount"
                                placeholder="Enter Amount"
                                @input="() => delete item.message.amount"
                              />
                            </div>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Report Name -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_report_name_${index}`"
                          :name="
                            index == 0
                              ? 'Report Name'
                              : 'Report Name (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Report Name'
                                : 'Report Name (' + index + '):'
                            "
                            :label-for="`import_report_name_${index}`"
                            label-cols-md="4"
                          >
                            <div :class="!item.report_name ? 'redBorder' : ''">
                              <b-form-input
                                :id="`import_report_name_${index}`"
                                :state="getValidationState(validationContext)"
                                v-model="item.report_name"
                                placeholder="Enter Report Name"
                              />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Report Date -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_report_date_${index}`"
                          :name="
                            index == 0
                              ? 'Report Date'
                              : 'Report Date (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Report Date'
                                : 'Report Date (' + index + '):'
                            "
                            :label-for="'report_date' + index"
                            label-cols-md="4"
                          >
                            <div :class="!item.report_date ? 'redBorder' : ''">
                              <flat-pickr
                                placeholder="Select report date"
                                v-model="item.report_date"
                                class="form-control"
                              />
                            </div>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Field: import Remarks -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`im_remarks_${index}`"
                          :name="
                            index == 0 ? 'Remarks' : 'Remarks (' + index + ')'
                          "
                          rules="required"
                        >
                          <b-form-group
                            class="required"
                            :label="
                              index == 0
                                ? 'Remarks'
                                : 'Remarks (' + index + '):'
                            "
                            :label-for="`import_remarks_${index}`"
                            label-cols-md="4"
                          >
                            <div :class="!item.remarks ? 'redBorder' : ''">
                              <b-form-input
                                :id="`import_remarks_${index}`"
                                :state="getValidationState(validationContext)"
                                v-model="item.remarks"
                                placeholder="Enter Remarks"
                              />
                            </div>
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between border-left py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="deleteImportFormList(index)"
                      />
                    </div>
                  </div>
                </b-col>
                <div class="w-100 p-2" v-if="item.message">
                  <pre
                    class="ml-sm-2 mb-0 redColor"
                    style="white-space: break-spaces"
                    v-for="(item, index) of Object.values(item.message)"
                    :key="'message_' + index"
                    >{{ item }}</pre
                  >
                </div>
              </b-row>
            </div>
          </div>
          <div id="normal-form" v-else>
            <b-row class="mt-2 mt-sm-3">
              <!-- Field: Retrocession Year -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Year"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Year:"
                    label-for="year"
                    label-cols-md="4"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="year"
                        v-model="retrocessionData.year"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="years"
                        :clearable="false"
                        placeholder="Select year"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Retrocession Quarter -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Quarter"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Quarter:"
                    label-for="quarter"
                    label-cols-md="4"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="quarter"
                        v-model="retrocessionData.quarter"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="formOptions.quarter"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        placeholder="Select quarter"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />

            <b-row class="mt-1">
              <!-- Field: Retrocession JVSakk A/C No. / client_id -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="JVSakk A/C No."
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="JVSakk A/C No.:"
                    label-for="client_id"
                    label-cols-md="4"
                  >
                    <span v-if="retrocessionData.client_id" class="mr-2">{{
                      retrocessionData.client_id
                    }}</span>
                    <b-button
                      class="bgColor3 px-3"
                      @click="openJVModal(retrocessionData)"
                      >Select</b-button
                    >
                    <b-form-input
                      id="client_id"
                      trim
                      placeholder=""
                      style="display: none"
                      v-model="retrocessionData.client_id"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Retrocession Custodian Bank / bank_id-->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Custodian Bank"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Custodian Bank:"
                    label-for="bank"
                    label-cols-md="4"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="bank"
                        v-model="retrocessionData.bank_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          bankOptions.filter((bank) =>
                            availableBank.includes(bank.id)
                          )
                        "
                        label="name"
                        :clearable="false"
                        :reduce="(option) => option.id"
                        placeholder="Select custodian bank"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />

            <b-row class="mt-1">
              <!-- Field: Retrocession Account No -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Account No."
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Account No.:"
                    label-for="client_bank_id"
                    label-cols-md="4"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="client_bank_id"
                        v-model="retrocessionData.client_bank_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          clientBankOptions.filter(
                            (account) =>
                              account.bank_id == retrocessionData.bank_id &&
                              account.client_id == retrocessionData.client_id
                          )
                        "
                        :clearable="false"
                        label="account_number"
                        :reduce="(option) => option.id"
                        placeholder="Select account no."
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Retrocession Client Name -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Client Name"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Client Name:"
                    label-for="client_name"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="client_name"
                      :state="getValidationState(validationContext)"
                      v-model="retrocessionData.client_name"
                      placeholder="Enter Client Name"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />

            <b-row class="mt-1">
              <!-- Field: Retrocession Chinese Name -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Chinese Name"
                  rules=""
                >
                  <b-form-group
                    class=""
                    label="Chinese Name:"
                    label-for="chinese_name"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="chinese_name"
                      :state="getValidationState(validationContext)"
                      v-model="retrocessionData.chinese_name"
                      placeholder="Enter client’s Chinese name"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Retrocession Relationship Manager -->

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Relationship Manager"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Relationship Manager:"
                    label-for="relationship_manager_id"
                    label-cols-md="4"
                  >
                    <div class="form-col-select">
                      <v-select
                        id="relationship_manager_id"
                        v-model="retrocessionData.relationship_manager_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="userOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        placeholder="Select Relationship Manager"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />
            <div v-if="(retrocessionData.id = 0)">
              <div
                class="position-relative"
                v-for="(amount, index) in retrocessionData.amounts"
                :key="'amount' + index"
              >
                <b-row class="mt-1">
                  <!-- Field: Retrocession Currency -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="
                        index == 0 ? 'Currency' : 'Currency(' + index + ')'
                      "
                      rules="required"
                    >
                      <b-form-group
                        class="required"
                        :label="
                          index == 0 ? 'Currency' : 'Currency(' + index + '):'
                        "
                        :label-for="
                          index == 0 ? 'currency' : 'currency_' + index
                        "
                        label-cols-md="4"
                      >
                        <div class="form-col-select">
                          <v-select
                            :id="index == 0 ? 'currency' : 'currency_' + index"
                            v-model="amount.currency_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="currencyOptions"
                            :clearable="false"
                            label="currency"
                            :reduce="(option) => option.id"
                            placeholder="Select currency"
                          />
                          <feather-icon size="18" icon="ChevronDownIcon" />

                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Retrocession Amount -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="index == 0 ? 'Amount' : 'Amount(' + index + ')'"
                      rules="required"
                    >
                      <b-form-group
                        class="required"
                        :label="
                          index == 0 ? 'Amount' : 'Amount(' + index + '):'
                        "
                        :label-for="index == 0 ? 'amount' : 'amount_' + index"
                        label-cols-md="4"
                      >
                        <b-form-input
                          :id="index == 0 ? 'amount' : 'amount_' + index"
                          :state="getValidationState(validationContext)"
                          v-model="amount.amount"
                          placeholder="Enter Amount"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <a
                  v-if="index == 0"
                  href="#"
                  class="curr-btn addCurr"
                  @click.prevent.stop="addCurrfield"
                >
                  <feather-icon size="24" icon="PlusCircleIcon"
                /></a>
                <a
                  v-else
                  href="#"
                  class="curr-btn reduceCurr"
                  @click.prevent.stop="reduceCurrfield(index)"
                >
                  <feather-icon size="24" icon="MinusCircleIcon"
                /></a>
                <hr />
              </div>
            </div>
            <div v-else>
              <b-row class="mt-1">
                <!-- Field: Retrocession Currency -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    :name="'Currency'"
                    rules="required"
                  >
                    <b-form-group
                      class="required"
                      :label="'Currency'"
                      label-cols-md="4"
                    >
                      <div class="form-col-select">
                        <v-select
                          :id="'currency'"
                          v-model="retrocessionData.currency_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="currencyOptions"
                          :clearable="false"
                          label="currency"
                          :reduce="(option) => option.id"
                          placeholder="Select currency"
                        />
                        <feather-icon size="18" icon="ChevronDownIcon" />

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Retrocession Amount -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="validationContext"
                    :name="'Amount'"
                    rules="required"
                  >
                    <b-form-group
                      class="required"
                      :label="'Amount'"
                      :label-for="'amount'"
                      label-cols-md="4"
                    >
                      <b-form-input
                        :id="'amount'"
                        :state="getValidationState(validationContext)"
                        v-model="retrocessionData.amount"
                        placeholder="Enter Amount"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>

            <b-row class="mt-1">
              <!-- Field: Retrocession Report Name -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Report Name"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Report Name:"
                    label-for="report-name"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="report_name"
                      :state="getValidationState(validationContext)"
                      v-model="retrocessionData.report_name"
                      placeholder="Enter Report Name"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Retrocession Report Date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Report Date"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Report Date:"
                    label-for="report-date"
                    label-cols-md="4"
                  >
                    <flat-pickr
                      placeholder="Select report date"
                      v-model="retrocessionData.report_date"
                      class="form-control"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr />

            <b-row class="mt-1">
              <!-- Field: Retrocession Remarks -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="Remarks"
                  rules="required"
                >
                  <b-form-group
                    class="required"
                    label="Remarks:"
                    label-for="remarks"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="remarks"
                      :state="getValidationState(validationContext)"
                      v-model="retrocessionData.remarks"
                      placeholder="Enter Remarks"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
          <hr v-if="dataArr.length == 0" />
          <div class="d-flex mt-2 flex-wrap justify-content-between">
            <div class="d-flex mb-2 align-items-center">
              <span class="mx-auto">OR</span>
              <b-button
                class="btn-primary-green btn-trigger-export ml-1 mobile-w100"
                @click="$refs.importFile.$el.childNodes[0].click()"
              >
                <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                Import Excel File
              </b-button>
              <b-form-file
                style="display: none"
                type="file"
                @change="importExcel"
                id="upload"
                name=""
                ref="importFile"
              >
              </b-form-file>
            </div>

            <div class="mobile-w100">
              <!-- :fields="download excel -->
              <b-button
                @click="exportApiExport"
                class="btn btn-primary-green text-white btn-trigger-export mr-sm-2 mobile-w100"
              >
                <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                Export Form Template
              </b-button>
              <!-- <vue-excel-xlsx
                  class="btn btn-primary-green text-white btn-trigger-export mr-sm-2 mobile-w100"
                  :data="excel_template"
                  :columns="retrocessionData_column"
                  :file-name="'filename'"
                  :file-type="'xlsx'"
                  :sheet-name="'sheetname'"
                >
                  <feather-icon icon="FileTextIcon" class="mr-1" size="16" />
                  Export Form Template
                </vue-excel-xlsx> -->
              <b-button
                ref="submitRetrocessionForm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-sm-2 mt-1 mt-sm-0 mobile-w100"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="hide"
                variant="outline-danger"
                class="custom-cancel"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-form>

        <b-modal id="modal-jvsakk_ac" size="lg" scrollable>
          <template #modal-header>
            <div class="w-100">
              <div class="modal-top">
                <h3 class="modal-title font-weight-bolder">
                  Select from Client Database
                </h3>
              </div>
              <div class="search-primary no_px w-100">
                <feather-icon size="16" icon="SearchIcon" />
                <b-form-input
                  id="search-jvsakk_ac"
                  trim
                  type="search"
                  placeholder="Find Client"
                  v-model="jvsakk_ac.search"
                />
              </div>
            </div>
          </template>

          <div
            v-if="jvsakk_ac_filterOptions.length > 0"
            class="modal-table-wrapper px-1"
          >
            <div class="thead">
              <div class="tr">
                <div
                  class="th"
                  v-for="(th, index) in jvsakk_ac.fileds"
                  :key="'thKey' + index"
                >
                  {{ th }}
                </div>
              </div>
            </div>
            <div class="tbody">
              <div
                class="tr"
                v-for="(jv, index) in jvsakk_ac_filterOptions"
                :key="'jvsakk_ac_Option' + index"
                @click="jvsakk_ac.select = index"
                :class="jvsakk_ac.select == index ? 'select' : ''"
              >
                <div
                  class="td"
                  v-for="(item, index) in jvsakk_ac_OptionsKeyArray"
                  :key="'jvsakk_ac_OptionItem' + index"
                >
                  {{ jv[item] }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-box">No Search Result</div>
          <template #modal-footer="{ hide }">
            <b-button
              class="btn-select"
              variant="primary"
              type="submit"
              @click="confirmSelectJvAc"
            >
              <feather-icon size="14" icon="CheckCircleIcon" />
              Select
            </b-button>
            <b-button class="btn-cancal" variant="primary" @click="hide()">
              <feather-icon size="14" icon="XCircleIcon" /> Cancel
            </b-button>
          </template>
        </b-modal>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
  BCard,
  BCardBody,
  BTable,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { heightTransition } from "@core/mixins/ui/transition";
import formValidation from "@core/comp-functions/forms/form-validation";
import * as xlsx from "xlsx";
import axiosIns from "@/libs/axios";
import router from "@/router";

export default {
  data() {
    return {
      // user_id: getUserData().id,
      authorization_type_id: null,
      dataArr: [], // Table content data array
      tableLoading: false, // Whether the table is loading
      trigger: true,
      availableBank: [],
      JVmodalData: "",
      retrocessionData_fields: {
        Year: "year",
        Quarter: "quarter",
        "Account No.": "account_number",
        USD: "usd",
        HKD: "hkd",
        CHF: "chf",
        EUR: "eur",
        SGD: "sgd",
        "Report Name": "report_name",
        "Report Date": "report_date",
        Remarks: "remarks",
      },
      retrocessionData_column: [
        {
          label: "Year",
          field: "year",
        },
        {
          label: "Quarter",
          field: "quarter",
        },
        {
          label: "Account No.",
          field: "account_number",
        },
        {
          label: "USD",
          field: "usd",
        },
        {
          label: "HKD",
          field: "hkd",
        },
        {
          label: "CHF",
          field: "chf",
        },
        {
          label: "EUR",
          field: "eur",
        },
        {
          label: "SGD",
          field: "sgd",
        },
        {
          label: "Report Name",
          field: "report_name",
        },
        {
          label: "Report Date",
          field: "report_date",
        },
        {
          label: "Remarks",
          field: "remarks",
        },
      ],
      excel_template: [
        {
          year: "",
          quarter: "",
          account_number: "",
          usd: "",
          hkd: "",
          chf: "",
          eur: "",
          sgd: "",
          report_name: "",
          report_date: "",
          remarks: "",
        },
      ],
    };
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BFormFile,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    BTable,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    sumbmitAForm() {
      const res =
        this.dataArr.length > 0
          ? { data: this.dataArr }
          : this.retrocessionData;
      const url =
        this.dataArr.length > 0
          ? "app-retrocession/addMultiRetrocession"
          : "app-retrocession/addRetrocession";
      if (router.currentRoute.params.id) {
        res.id = router.currentRoute.params.id;
      }
      store
        .dispatch(url, res)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
          this.$router.replace("/retrocession");

          // if (this.retrocessionData.id == 0) {
          //   this.retrocessionData.id = response.data.id;
          //   this.redirect(response.data.id);
          // }
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.sumbmitAForm();
        }
      });
    },
    hide() {
      this.$router.replace("/retrocession");
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addCurrfield() {
      this.retrocessionData.amounts = [
        ...this.retrocessionData.amounts,
        { curr: "", number: "" },
      ];
    },
    reduceCurrfield(index) {
      this.retrocessionData.amounts.splice(index, 1);
    },
    openJVModal(item) {
      this.$bvModal.show("modal-jvsakk_ac");
      this.JVmodalData = item;
    },
    confirmSelectJvAc: function () {
      if (this.jvsakk_ac.select != null) {
        const index = this.jvsakk_ac.select;
        this.jvsakk_ac.selected = index;
        console.log("JVmodalData", this.JVmodalData);
        this.JVmodalData.client_id = parseInt(
          this.jvsakk_ac.options[this.jvsakk_ac.selected].jvsakk_ac
        );
        // find the client_bank_record
        let client_bank = this.clientBankOptions[this.jvsakk_ac.selected];
        console.log(client_bank);
        this.JVmodalData.client_name = client_bank.client.name_en;
        this.JVmodalData.chinese_name = client_bank.client.name_cn;
        this.JVmodalData.relationship_manager_id =
          client_bank.client.relationship_manager_id;
        this.JVmodalData.client_bank_id = client_bank.id;
        this.JVmodalData.bank_id = client_bank.bank_id;
        let all_client_bank = this.clientBankOptions.filter(
          (bank) => bank.client_id == this.JVmodalData.client_id
        );
        this.availableBank = [];
        this.availableBank = all_client_bank.map((bank) => bank.bank_id);
        this.trigger = !this.trigger;
        this.$bvModal.hide("modal-jvsakk_ac");
        if (
          this.JVmodalData.message &&
          this.JVmodalData.message.account_number
        ) {
          delete this.JVmodalData.message.account_number;
        }
      } else {
        this.$bvToast.toast(`Please select an Account`, {
          title: "You Don't select any Account",
          autoHideDelay: 2000,
        });
      }
    },

    getHeader(sheet) {
      const XLSX = xlsx;
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]); // worksheet['!ref'] Is the valid range of the worksheet
      // console.log("range", range);
      let C;
      /* Get cell value start in the first row */
      const R = range.s.r; //Line / / column C
      let i = 0;
      for (C = range.s.c; C <= range.e.c; ++C) {
        var cell =
          sheet[
            XLSX.utils.encode_cell({ c: C, r: R })
          ]; /* Get the cell value based on the address  find the cell in the first row */
        var hdr = "UNKNOWN" + C; // replace with your desired default
        // XLSX.utils.format_cell Generate cell text value
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        if (hdr.indexOf("UNKNOWN") > -1) {
          if (!i) {
            hdr = "__EMPTY";
          } else {
            hdr = "__EMPTY_" + i;
          }
          i++;
        }
        headers.push(hdr);
      }

      return headers;
    },
    importExcel(e) {
      const files = e.target.files;

      function areEqual(array1, array2) {
        if (array1.length === array2.length) {
          return array1.every((element, index) => {
            if (element === array2[index]) {
              return true;
            }

            return false;
          });
        }
        return false;
      }

      // console.log(files);
      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "The upload format is incorrect. Please upload xlsx format"
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary",
          });

          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets

          const a = workbook.Sheets[workbook.SheetNames[0]];
          console.log("sh", a);
          const headers = this.getHeader(a);

          if (!areEqual(headers, Object.keys(this.retrocessionData_fields))) {
            this.$bvToast.toast(
              `Please use our template, you can click "Export Form Template" button to download template`,
              {
                title: "Excel file format is not corrent!",
                variant: "danger",
                autoHideDelay: 5000,
              }
            );
            return;
          }

          function ExcelDateToJSDate(serial) {
            if (isNaN(serial)) {
              return null;
            }
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);

            var fractional_day = serial - Math.floor(serial) + 0.0000001;

            var total_seconds = Math.floor(86400 * fractional_day);

            var seconds = total_seconds % 60;

            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;
            var month = date_info.getMonth() + 1;
            month = month < 10 ? "0" + month : month;

            var day = date_info.getDate();
            day = day < 10 ? "0" + day : day;

            return `${date_info.getFullYear()}-${month}-${day}`;
          }

          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: headers,
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet

          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            if (i > 0) {
              ws[i]["Report Date"] = ExcelDateToJSDate(ws[i]["Report Date"]);
            }
            excellist.push(ws[i]);
          }
          // console.log("Read results", excellist); // At this point, you get an array containing objects that need to be processed

          this.setTable(headers, excellist);
        } catch (e) {
          return alert("Read failure!");
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    },

    setTable(headers, excellist) {
      const newTableData = []; // set table content data
      const checkList = []; // set check table content data

      const renderCheckList = (list, newList) => {
        list.forEach((item, index) => {
          const newObj = {};
          if (index > 0) {
            Object.keys(item).forEach((key) => {
              newObj[this.retrocessionData_fields[key]] = item[key];
            });
            newList.push(newObj);
          }
        });
      };
      renderCheckList(excellist, checkList);

      if (checkList.length <= 0) {
        this.$bvToast.toast(
          `You didn't input any thing of your imported excel`,
          {
            title: "Please input the field!",
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }
      console.log(checkList);
      axiosIns
        .post("/retrocession/checkImportData", { data: checkList })
        .then((response) => {
          console.log("response", response.data.data);
          this.dataArr = response.data.data;
        })
        .catch((error) => {
          console.log("ddd", error);
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    exportApiExport() {
      store.dispatch("app-retrocession/getRequestTemplate").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Retrocession Template"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    deleteImportFormList(index) {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.dataArr.splice(index, 1);
        }
      });
    },
  },
  props: {
    filterTableOptions: {
      type: Array,
      required: true,
    },
    retrocessionData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    bankOptions: {
      type: Array,
      required: true,
    },
    clientBankOptions: {
      type: Array,
      required: true,
    },
    currencyOptions: {
      type: Array,
      required: true,
    },
    jvsakk_ac: {
      type: Object,
      required: true,
    },
  },
  computed: {
    jvsakk_ac_filterOptions() {
      return this.jvsakk_ac.options.filter((item) => {
        console.log(this.jvsakk_ac.search);
        console.log(item);
        return Object.values(item).some((value) => {
          return (
            value !== null &&
            value.toLowerCase().indexOf(this.jvsakk_ac.search.toLowerCase()) >
              -1
          );
        });
      });
    },
    jvsakk_ac_OptionsKeyArray() {
      return Object.keys(this.jvsakk_ac.options[0]);
    },
  },
  setup(props, { emit }) {
    console.log(props.retrocessionData);
    const loading = ref(false);
    const button_text = ref("Save");
    const resetretrocessionData = () => {
      props.retrocessionData = JSON.parse(JSON.stringify({}));
    };

    // const itemFormBlankItem = {
    //     id: 0,
    //     authorization_type_id: null,
    //     date_of_authorised: null,
    //     date_of_removed: null,
    // };
    let years = [];
    let year = new Date().getFullYear();
    for (var i = year; i > year - 10; i--) {
      years.push(i);
    }

    let formOptions = {
      quarter: [
        { id: 1, name: "Q1" },
        { id: 2, name: "Q2" },
        { id: 3, name: "Q3" },
        { id: 4, name: "Q4" },
      ],
    };
    formOptions = ref(formOptions);

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetretrocessionData
    );

    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      // itemFormBlankItem,
      years,
      formOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
#normal-form .form-group {
  margin-bottom: 0px;
}

.modal-table-wrapper {
  .tr {
    .th,
    .td {
      padding: 1rem;
      min-width: 130px;

      &:nth-child(4) {
        min-width: 175px;
      }
    }
  }
}

.close-excel-tb {
  position: absolute;
  right: 0px;
  top: -40px;
}

@media screen and (max-width: 640px) {
  .custom-cancel {
    width: 100%;
    margin-top: 1rem;
  }
}
</style>
